

//demo
import companyName from '../store/mobxStore/companyName';
const COMPANY_NAME = companyName.companyName ?? 'Company Name';
const BASE_URL = "https://aleksandr.mlmadmin.iossmlm.com/nodeapi/web/";
const IMG_URL = "https://aleksandr.mlmadmin.iossmlm.com/nodeapi/uploads/images/";
const LEAD_URL = "https://aleksandr.mlmadmin.iossmlm.com/nodeapi/web/";
const BASE = "https://aleksandr.mlmadmin.iossmlm.com/";
const API_KEY = localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY ='903'
const DEFAULT_KEY = '387245a5a919ea2071cc406b10b89d4685e5cc8e'
// fdf

//live

// const BASE_URL = "http://208.109.189.52:5000/nodeapi/web/";
// const IMG_URL = "http://208.109.189.52:5000/nodeapi/uploads/images/";
// const LEAD_URL = "http://208.109.189.52:5000/nodeapi/web/";
// const BASE = "http://208.109.189.52:5000/";



export {
  COMPANY_NAME,
  BASE_URL,
  IMG_URL,
  API_KEY,
  BASE,
  DEFAULT_KEY,
  LEAD_URL,
};
