import React, { useState } from "react";
import style from "./support.module.scss";
import {
  Row,
  Col,
  Card,
  Form,
  Tabs,
  Tab,
  Button,
  Accordion,
} from "react-bootstrap";
import { AddonNotification } from "../common";
import moment from "moment";
import DataTable from "react-data-table-component";
import { FormInputControl, DropDownBs } from "../common";
import AlertBs from "../common/AlertBs";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const SupportContent = (props) => {
  const { t } = useTranslation();
  const { currentTheme } = useSelector((state) => state.Theme);
  const [show, setShow] = useState(true);
  const columns = [
    {
      name: "#",
      cell: (row, index) => index + 1,
    },
    {
      name: t("support.ticketID"),
      selector: (row) => `${row.ticket_id}`,
      cell: (row) => (
        <Link
          to={`/support/ticket-details/${row.ticket_id}`}
          className="btn-link  text-primary"
          title="timeline"
        >
          {row.ticket_id}
        </Link>
      ),
    },
    {
      name: t("support.subject"),
      // selector: 'subject'
      selector: (row) => `${row.subject}`,
    },
    {
      name: t("support.assignee"),
      // selector: 'assignee_user_name'
      selector: (row) => `${row.assignee_user_name}`,
    },
    {
      name: t("support.status"),
      // selector: 'status_name'
      selector: (row) => `${row.status_name}`,
    },
    {
      name: t("support.category"),
      // selector: 'category_name'
      selector: (row) => `${row.category_name}`,
    },
    {
      name: t("support.priority"),
      // selector: 'priority_name'
      selector: (row) => `${row.priority_name}`,
      // cell: row => moment(row.priority_name),
    },
    {
      name: t("support.createdOn"),
      // selector: 'created_at',
      selector: (row) => `${row.created_at}`,
      cell: (row) => moment(row.created_at).format("D MMM YY-h:mm:ss a"),
    },
    {
      name: t("support.lastUpdated"),
      // selector: 'updated_at',
      selector: (row) => `${row.updated_at}`,
      cell: (row) => moment(row.updated_at).format("D MMM YY-h:mm:ss a"),
    },
    // {
    //     name: t('support.reopen'),
    //     cell: row => {
    //         if (row.status_name === "Resolved") {
    //             return (
    //                 <button className="btn btn-teal tooltips">
    //                     <i className="fa fa-edit"></i>
    //                 </button>
    //             )
    //         }
    //     }
    // },
    {
      name: t("support.timeLine"),
      cell: (row) => (
        <Link
          to={`/support/ticket-time-line/${row.ticket_id}`}
          className="btn-link h4 text-primary"
          title="timeline"
        >
          <i className={`fa fa-arrows-alt  ${style.icon}`}></i>
        </Link>
      ),
    },
  ];

  const statuses = props.filters?.statuses.map((status) => (
    <option value={status.id} key={status.id}>
      {status.status}
    </option>
  ));
  const priorities = props.filters?.priorities.map((priority) => (
    <option value={priority.id} key={priority.id}>
      {priority.priority}
    </option>
  ));
  const categories =
    props.filters?.categories &&
    props.filters.categories.map((category) => (
      <option value={category.id} key={category.id}>
        {category.category_name}
      </option>
    ));

  const faqs = props.faqs.map((faq, key) => {
    return (
      <Card key={key} className={style.accorionItem}>
        <Card.Header className={style.cardHeader}>
          {faq.question}
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={++key}
            className={style.plusIcon}
          >
            <span>
              <i className="fa fa-plus-circle"></i>
            </span>
          </Accordion.Toggle>
        </Card.Header>

        <Accordion.Collapse eventKey={key} className={`accordian`}>
          <Card.Body>{faq.answer}</Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  });

  return (
    <div className={style.container}>
    
      <Row>
        <Col>
          <AlertBs {...props.alert} close={props.closeAlert} />
          <Tabs defaultActiveKey="my_tickets" id="tab" className="mb-3">
            <Tab eventKey="my_tickets" title={t("support.myTickets")}>
              <Card>
                <Card.Body>
                  <div className={style.borderColumn}>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label style={{ fontSize: "10px" }}>
                            {t("support.ticketID")}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            value={props.ticket_filters.ticket_id}
                            onChange={(e) =>
                              props.changeTicketFilter("ticket_id", e)
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>{t("support.category")}</Form.Label>
                          <Form.Control
                            as="select"
                            value={props.ticket_filters.category}
                            onChange={(e) =>
                              props.changeTicketFilter("category", e)
                            }
                          >
                            <option value="">
                              {t("support.selectCategory")}
                            </option>
                            {categories}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>{t("support.priority")}</Form.Label>
                          <Form.Control
                            as="select"
                            value={props.ticket_filters.priority}
                            onChange={(e) =>
                              props.changeTicketFilter("priority", e)
                            }
                          >
                            <option value="">
                              {t("support.selectPriority")}
                            </option>
                            {priorities}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>{t("support.status")}</Form.Label>
                          <Form.Control
                            as="select"
                            value={props.ticket_filters.status}
                            onChange={(e) =>
                              props.changeTicketFilter("status", e)
                            }
                          >
                            <option value="">
                              {t("support.selectStatus")}
                            </option>
                            {statuses}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className={style.filterButtons}>
                          <Button
                            variant="primary"
                            style={{ marginRight: "10px" }}
                            disabled={props.localloading}
                            className={`"mr-2" ${style.searchButton}`}
                            onClick={props.searchTickets}
                          >
                            {props.localloading ? (
                              <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" }}
                              />
                            ) : (
                              ""
                            )}

                            {t("Button.search")}
                          </Button>
                          <Button
                            variant="secondary"
                            disabled={props.resetloading}
                            className={`"mr-2" ${style.resetButton}`}
                            onClick={props.resetTicketFilter}
                          >
                            {props.resetloading ? (
                              <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" }}
                              />
                            ) : (
                              ""
                            )}

                            {t("Button.reset")}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className={style.borderColumn}>
                    <Row>
                      <Col>
                        <DataTable
                          columns={columns}
                          data={props.tickets}
                          persistTableHead={true}
                        />
                      </Col>
                    </Row>
                  </div>
                </Card.Body>
              </Card>
            </Tab>
            <Tab eventKey="create_ticket" title={t("support.createTicket")}>
              <Card>
                <Card.Body>
                  <Form onSubmit={props.createTicket} method="POST">
                    <FormInputControl
                      label={t("support.subject")}
                      required={true}
                      error={props.FormError.subject}
                      inputProps={{
                        type: "text",
                        name: "subject",
                        value: props.create_ticket.subject,
                        onChange: props.changeCreateTicket,
                        // placeholder: t('support.subject')
                      }}
                    />

                    <DropDownBs
                      label={t("support.priority")}
                      required={true}
                      error={props.FormError.priority}
                      preOption={t("support.selectPriority")}
                      DropdownProps={{
                        name: "priority",
                        onChange: props.changeCreateTicket,
                      }}
                      options={{
                        data: props.filters.priorities,
                        value: "id",
                        label: "priority",
                      }}
                    ></DropDownBs>

                    <DropDownBs
                      label={t("support.category")}
                      required={true}
                      error={props.FormError.category}
                      preOption={t("support.selectCategory")}
                      DropdownProps={{
                        name: "category",
                        onChange: props.changeCreateTicket,
                      }}
                      options={{
                        data: props.filters.categories,
                        value: "id",
                        label: "category_name",
                      }}
                    ></DropDownBs>

                    <FormInputControl
                      inputClass={`${
                        currentTheme == "theme-dark" &&
                        `${style.dark_mode_textArea}`
                      }`}
                      label={t("support.messageToAdmin")}
                      required={true}
                      error={props.FormError.message_to_admin}
                      inputProps={{
                        as: "textarea",
                        name: "message_to_admin",
                        value: props.create_ticket.message_to_admin,
                        onChange: props.changeCreateTicket,
                        // placeholder: t('support.messageToAdmin'),
                        rows: 4,
                      }}
                    />

                    <FormInputControl
                      label={t("support.attachment")}
                      error={props.FormError.attachment}
                      inputProps={{
                        type: "file",
                        name: "attachment",
                        onChange: props.changeCreateTicket,
                      }}
                    />

                    <Button
                      variant="primary"
                      type="submit"
                      disabled={props.submitloader}
                      className={style.submittButton}
                    >
                      {props.submitloader ? (
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{ marginRight: "5px" }}
                        />
                      ) : (
                        ""
                      )}{" "}
                      {t("Button.submit")}
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Tab>
            <Tab eventKey="faq" title={t("support.FAQs")}>
              <Card>
                <Card.Body>
                  {show && (
                    <div className={style.alertFaq} role="alert">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        onClick={props.closeAlert}
                      >
                        <span aria-hidden="true">×</span>
                        <span className="sr-only"> {t("button.Close")} </span>
                      </button>
                      {t("support.answerToSome")} <strong>Company Name</strong>{" "}
                      . {t("support.ifYouCannotFind")}
                    </div>
                  )}
                  <Accordion>{faqs}</Accordion>
                </Card.Body>
              </Card>
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default SupportContent;
