import { Row, Col, Card, Form, Button } from "react-bootstrap";
import React from "react";
import style from "./tools.module.scss";
import AddonNotification from "../../components/common/AddonNotification";
import { useTranslation } from "react-i18next";
// import {useTranslation} from 'react-i18next';
// import moment from 'moment';
import AlertBs from "../common/AlertBs";
import { useSelector } from "react-redux";

const ReplicaContent = (props) => {
  const { t } = useTranslation();
  const { currentTheme } = useSelector((state) => state.Theme);
  let imagePreview = "";
  if (props.changedImage.show) {
    imagePreview = (
      <div className={style.previewZone}>
        <div className={style.box}>
          <div className={style.boxHeader}>
            <div>{t("Button.preview")}</div>
            <div className={style.boxTools}>
              <button
                type="button"
                onClick={props.resetBanner}
                className={style.removePreview}
              >
                <i className={style.faTimes}></i>
                {t("Button.reset")}
              </button>
            </div>
          </div>
          <div className={style.boxBody}>
            <img width="200" src={props.changedImage.file} alt="" />
            <p>{props.changedImage.name}</p>
          </div>
        </div>
      </div>
    );
  }

  let uploadBtn = "";
  if (props.changedImage.uploadFile) {
    uploadBtn = (
      <Button
        variant="primary"
        type="button"
        onClick={props.uploadBanner}
        className={`"mtp30 ml-1" ${style.uploadButton}`}
      >
        {props.uploader ? (
          <i className="fa fa-refresh fa-spin" style={{ marginRight: "5px" }} />
        ) : (
          ""
        )}
        Upload
      </Button>
    );
  }

  return (
    <div className={style.downloadConatiner}>
 
      <Row>
        <Col>
          <AlertBs {...props.alert} close={props.closeAlert} />
          <Card>
            <Card.Body
              className={`${
                currentTheme == "theme-dark"
                  ? `${style.dark_mode_replicaContent}`
                  : `${style.replicaContent}`
              }`}
            >
              <div>
                <h4
                  className={`${
                    currentTheme == "theme-dark" && `${style.dark_mode_heading}`
                  }`}
                >
                  {t("Common.currenttopbanner")}
                </h4>
                <div className={style.uploadContainer}>
                  <img src={props.replica_banner} alt="" />
                </div>

                {imagePreview}

                <Form.Label>{t("Common.uploadtopbanner")}</Form.Label>

                <div className={style.dropzoneWrapper}>
                  <div className={style.dropzoneDesc}>
                    <i className="fa fa-download" aria-hidden="true"></i>
                    <p>{t("Common.chooseimagefiledrag")}</p>
                    <div className={style.dropzoneDesc2}>
                      <span
                        className={`${
                          currentTheme == "theme-dark" &&
                          `${style.dark_mode_heading}`
                        }`}
                      >
                        {t("Common.chooseimgfile")}
                      </span>
                      <br />
                      <span
                        className={`${
                          currentTheme == "theme-dark" &&
                          `${style.dark_mode_heading}`
                        }`}
                      >
                        {t("Common.maxsize")}
                      </span>
                    </div>
                  </div>
                  <input
                    type="file"
                    name="banner_image"
                    id="banner_image"
                    className={style.dropzone}
                    onChange={props.changeBanner}
                  />
                </div>
              </div>
              {uploadBtn}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ReplicaContent;
