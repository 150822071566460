import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import PageTitle from "../../../components/common/pageTitle";
import { useTranslation, withTranslation } from "react-i18next";
import ReplicaRegService from "../../../../service/Auth/Replicareg";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//components
import { Content } from "../../../components/replicareg";
// import Test from '../../../components/registration/Test';
import Spinner from "../../../shared/Spinner";
//style
import style from "../../../components/replicareg/registration.module.scss";
import companyName from "../../../../store/mobxStore/companyName";

import Payments from "../../../../service/common/PaymentApi";

import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

const mapStateToProps = (state) => {
  return { mlmPlan: state.ProjectConfig.mlmPlan };
};

const Register = ({ mlmPlan }) => {
  //  let apiservice = new RegisterService();
  let _isMounted = false;
  const params = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  //    const handleChange = e =>{

  //     const allowedKeys = [
  //         "Delete",
  //         "ArrowLeft",
  //         "ArrowRight",
  //         "Backspace",
  //         "Home",
  //         "End",
  //         "Enter",
  //         "Tab"
  //       ];
  //       if (e.key === "Delete") {
  //         console.log("Delete is pressed")
  //       }

  //         }

  // const [isMounted, setIsMounted] = useState(false);
  const [state, setState] = useState({
    error: "",
    message: "",
    data: "",
    loading: true,
    sponser_username: "",
  });
  const [clientSecret, setClientSecret] = useState("");
  const Service = new Payments();
  useEffect(() => {
    _isMounted = true;
    Service.createPayment("", "Paypal").then((res) => {
      if (res.status) {
        setClientSecret(res.data.public_key);
      }
    });
    getDetails();
    // handleChange();

    return () => (_isMounted = false);
  }, []);

  // useEffect(() => {

  //     return () => _isMounted = false;
  // }, [state.data])
  // useEffect(() => {
  //     return {
  //         _isMounted =false
  //     }

  // }, [])

  const getDetails = async () => {
    let apiservice = new ReplicaRegService();
    apiservice.RegisterDetails(params.sponser_id).then(async (res) => {
      if (_isMounted) {
        if (res.status) {
          const { userId, leg } = params;
          if (userId && leg && mlmPlan !== "Unilevel") {
            const newField = res.data.sponsor.fields.map((item) => {
              if (item.code === "position") {
                return {
                  ...item,
                  disabled: true,
                  value: leg,
                };
              }
              return item;
            });
            let placementUserName = {
              code: "placementUserName",
              field_name: "placement_user_name",
              isEditable: false,
              required: true,
              title: "Placement Username",
              type: "text",
              value: userId,
            };
            newField.splice(2, 0, placementUserName);
            const formData = {
              username: userId,
            };
            await apiservice.validateSponsorUserName(formData).then((res) => {
              if (res.status) {
                let placementFullName = {
                  code: "placementFullName",
                  field_name: "placement_full_name",
                  isEditable: false,
                  required: true,
                  title: "Placement Fullname",
                  type: "text",
                  value: res.data.sponsorFullName,
                };
                newField.splice(3, 0, placementFullName);
              } else {
                if (res.error.code === 1002) {
                  history.push("/logout");
                } else if (res.error.code === 1007) {
                  history.push({
                    pathname: "/genealogyTree",
                    state: {
                      error: true,
                      message: "invalidplacementUsername",
                    },
                  });
                }
              }
            });
            if (mlmPlan === "Binary") {
              await apiservice
                .checkPlacemntLegAvailability(userId, leg)
                .then((res) => {
                  if (!res.status) {
                    if (res.error.code === 1002) {
                      history.push("/logout");
                    } else if (res.error.code === 1033) {
                      history.push({
                        pathname: "/genealogyTree",
                        state: {
                          error: true,
                          message: "invalidPlacement",
                        },
                      });
                    } else if (res.error.code === 1057) {
                      history.push({
                        pathname: "/dashboard",
                        state: {
                          error: true,
                          message: "permissionDenied",
                        },
                      });
                    }
                  }
                });
            }
            res.data.sponsor.fields = newField;

            if (_isMounted) {
              setState({
                loading: false,
                ...res.data,
              });
            }
          } else {
            if (_isMounted) {
              const newField = res.data.sponsor.fields.map((item) => {
                if (item.code === "sponsorUserName") {
                  return {
                    ...item,
                    disabled: true,
                    value: params.sponser_id,
                  };
                }
                return item;
              });
              res.data.sponsor.fields = newField;
              setState({
                loading: false,
                ...res.data,
              });
            }
          }
        } else {
          if (res.error.code === 1002) {
            history.push("/logout");
          } else if (res.error.code === 1057) {
            history.push({
              pathname: "/dashboard",
              state: {
                error: true,
                message: "permissionDenied",
              },
            });
          }
        }
      }
    });
  };
  return (
    <>
      {state.loading && <Spinner />}
      <div className={`h-100`}>
        <Helmet>
          <title>
            {companyName.companyName} | {t("Sidemenu.register")}
          </title>
          <script
            src={`https://www.paypal.com/sdk/js?client-id=${clientSecret}&currency=USD`}
          ></script>
        </Helmet>
        {/* <PageTitle 
                    title="register" 
                    buttonOn={false}
                /> */}

        <div className={style.MainContainer}>
          {state.sponsor && (
            <Content data={state} sponserUsername={params.sponser_id} />
          )}
        </div>
      </div>
    </>
  );
};
export default withTranslation()(
  connect(mapStateToProps)(withRouter(Register))
);
